/**
 * Creates an array of elements split into groups the length of size. 
 * If array can't be split evenly, the final chunk will be the remaining elements.
 * @param {*[]} arr - The array to be chunked
 * @param {number} size - The chunk size
 */

const chunk = (arr = [], size) => {
	if (arr.length === 0 || size < 1) return [[]];
	
	return arr.reduce(
		(acc, curr, index) => (index % size === 0 ? acc.push([curr]) : acc[acc.length - 1].push(curr), acc),
    	[]
	);
};

export default chunk;