import Vue from 'vue'

import axiosIntents from '@/utils/axios-intents'
import downloadBlob from '@/utils/downloadBlob.js'
import StoreHash from '@/utils/StoreHash.js'
import i18n from '@/i18n';

const RECEIPTS_PAGES_LIFETIME = 5 * 60 * 1000 // 5 minute
const RECEIPTS_LIMIT_DEFAULT = 11

let cashed = {
    receipts: {}
}

export default {
    state: {
        receipts: {},
        receipts_total: 0,
        is_loading_receipts: false,
        receipts_loading_error: {},
        
        // single receipt
        is_loading_receipt: false,
        is_receipt_loading_error: false,
        is_downloading_receipt_pdf: false,
    },

    getters: {
        receipts: state => state.receipts,
        receipts_loading_error: state => state.receipts_loading_error,
        receipts_total: state => state.receipts_total,
        receipts_total_pages: (state, getters) => Math.ceil(getters.receipts_total / RECEIPTS_LIMIT_DEFAULT),
        has_receipts: (state, getters) => Boolean(getters.receipts_total),
        is_loading_receipts: state => state.is_loading_receipts,
        receiptsByPage: (state, getters) => (page) => getters.receipts[page] || [],
        receiptsLoadingErrorByPage: (state, getters) => (page) => getters.receipts_loading_error[page] || null,

        // single receipt
        is_loading_receipt: state => state.is_loading_receipt,
        is_receipt_loading_error: state => state.is_receipt_loading_error,
        is_downloading_receipt_pdf: state =>  state.is_downloading_receipt_pdf,
    },

    actions: {
        GetReceipts({getters, commit}, { page = 1, limit: Limit = RECEIPTS_LIMIT_DEFAULT } = {}) {
            if (getters.is_loading_receipts || !getters.is_app_environment_receipts_allowed) return false;

            if (
                cashed.receipts[page]
                && !cashed.receipts[page].expired()
                && getters.receiptsByPage(page).length
            ) {
                return getters.receiptsByPage(page)
            }

            const uuid = getters.current_account_uid

            commit('SET_IS_LOADING_RECEIPTS', true)

            return axiosIntents.get(`/customer/${ uuid }/receipt`, { params: { Limit, Offset: Limit * (page - 1) } }).then(result => {
                commit('SET_RECEIPTS_LOADING_ERROR', { page, error: null })

                const receipts = result?.data?.Receipts || []
                const total = result?.data?.PageInfo?.TotalItemCount || 0
                
                commit('SET_RECEIPTS', { total, page, receipts })

                return Promise.resolve(getters.receiptsByPage(page))
            })
            .catch(error => {
                console.log('GetReceipts error', error)

                commit('SET_RECEIPTS_LOADING_ERROR', { page, error })
        
                return Promise.resolve([])
            })
            .finally(()=> {
                commit('SET_IS_LOADING_RECEIPTS', false)
            })
        },

        // single receipt
        GetReceipt({getters, commit}, ReceiptUUID) {
            const uuid = getters.current_account_uid

            if (getters.is_loading_receipt || !getters.is_app_environment_receipts_allowed || !uuid) return null;

            commit('SET_IS_LOADING_RECEIPT', true)
            commit('SET_IS_RECEIPT_LOADING_ERROR', false)

            return axiosIntents.get(`/customer/${ uuid }/receipt/${ReceiptUUID}`).then(result => {
                const receipt = result?.data || null

                return receipt
            })
            .catch(error => {
                console.log('GetReceipt error', error)

                commit('SET_IS_RECEIPT_LOADING_ERROR', true)
        
                return null
            })
            .finally(()=> {
                commit('SET_IS_LOADING_RECEIPT', false)
            })
        },

        DownloadReceiptPdf({getters, commit}, receipt_uuid) {
            if (getters.is_downloading_receipt_pdf) return;

            const uuid = getters.current_account_uid

            if (!uuid) return Promise.reject(new Error(i18n.t('no_uuid')))
            if (!receipt_uuid) return Promise.reject(new Error(i18n.t('no_receipt_uuid')))
            
            commit('SET_IS_DOWNLOADING_RECEIPT_PDF', true)

            return axiosIntents.get(`/customer/${ uuid }/receipt/${receipt_uuid}/pdf`, {responseType: 'blob'})
                .then(({ result: blob }) => {
                    return downloadBlob(`${receipt_uuid}.pdf`, blob)
                })
                .catch(error => {
                    console.log('DownloadReceiptPdf error', error)

                    return Promise.reject(error)
                })
                .finally(()=> {
                    commit('SET_IS_DOWNLOADING_RECEIPT_PDF', false)
                })
        },
    },

    mutations: {
        SET_IS_LOADING_RECEIPTS(state, is_loading_receipts) {
            state.is_loading_receipts = is_loading_receipts
        },

        SET_IS_DOWNLOADING_RECEIPT_PDF(state, is_downloading_receipt_pdf) {
            state.is_downloading_receipt_pdf = is_downloading_receipt_pdf
        },

        SET_RECEIPTS(state, { total, page, receipts }) {
            if (state.receipts_total && state.receipts_total != total) {
                state.receipts = {}
                state.receipts_loading_error = {}
                cashed.receipts = {}
                state.is_loading_receipts = false
            }
        
            state.receipts_total = total
        
            Vue.set(state.receipts, page, receipts)
        
            if (!cashed.receipts[page]) {
                cashed.receipts[page] = new StoreHash(RECEIPTS_PAGES_LIFETIME)
            }
        
            cashed.receipts[page].fix()
        },

        SET_RECEIPTS_LOADING_ERROR(state, { page, error }) {
            Vue.set(state.receipts_loading_error, page, error)
        },
        
        RESET_RECEIPTS_LOADING_ERRORS(state) {
            state.receipts_loading_error = {}
        },
        
        RESET_RECEIPTS (state) {
            state.receipts = {}
            state.receipts_loading_error = {}
            cashed.receipts = {}
            state.is_loading_receipts = false
            state.receipts_total = 0
        },

        // single receipt
        SET_IS_LOADING_RECEIPT(state, is_loading_receipt) {
            state.is_loading_receipt = is_loading_receipt
        },

        SET_IS_RECEIPT_LOADING_ERROR(state, is_receipt_loading_error) {
            state.is_receipt_loading_error = is_receipt_loading_error
        },
    },
}
